@import "../../scss/rem-calc";
@import "../../scss/colors";

.matchList {
  overflow-y: auto;
  overflow-x: auto;
}

.table {
  min-width: 100%;
  margin-bottom: 0;

  .relevanceColumn {
    width: rem-calc(80px);
  }

  .selectionColumn {
    width: rem-calc(28px);
    padding-right: 0;

    :global(.custom-checkbox) {
      position: relative;
      top: 1px;
    }
  }

  .existingSelectionColumn {
    width: rem-calc(28px);
  }

  td.existingSelectionColumn {
    text-align: center;

    i {
      margin-top: 6px;
    }
  }

  .detailsColumn {
    max-width: 250px;
    white-space: pre-line;

    &.nowrap {
      white-space: nowrap;
    }
  }

  .matchedColumn {
    width: rem-calc(120px);
  }

  .missedColumn {
    width: rem-calc(120px);
  }

  .contextToggleColumn {
    width: rem-calc(120px);
  }
}

.header {
  white-space: nowrap;

  th {
    border-top: none;
  }
}
.selectable {
  cursor: pointer;

  &:hover td {
    background-color: #d9ecff;
  }
}

.headerCell {
  vertical-align: bottom !important;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.detailsButton {
  background: none;
  border: none;
  outline: none;
  padding: 0 2px;

  td:hover & {
    text-decoration: underline;
    background: none;
    border: none;
  }

  &:not(:disabled):not(:global(.disabled)):active {
    text-decoration: underline;
    background: none;
    border: none;
  }
}

.progress {
  top: 3px;
}

table.stickyHeader {
  thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
    padding: 0;
    border-bottom: 0;

    > div {
      padding: 0.3rem;
      border-bottom: 2px solid $gray-300;
    }
  }

  tbody tr:first-child td {
    border-top: none;
  }
}

.nowrap {
  white-space: nowrap;
}

.preline {
  white-space: pre-line;
}

.selectAllAmount {
  padding: 0.1rem;
  height: 21px;
  width: 30px;
}